<template>
  <div class="form">
    <div class="b-personal-profile content">
      <Backlink title="personalInformation" :service="currentServiceStr" />
      <div class="profile-container content__container">
        <div class="profile-container_information">
          <div class="profile-container_personal-data">
            <div class="profile-container_title">{{ "personalData" | localize }}</div>

            <v-text-field
              v-model="user.name"
              class="input profile-container_personal-data__field"
              :class="{ invalid: $v.user.name.$dirty && !$v.user.name.required }"
              :label="'name' | localize"
              :placeholder="'name' | localize"
              @input="checkForm($v.user.name)"
              @blur="$v.user.name.$touch()"
              :messages="'message_for_personal-data' | localize"
              :hint="($v.user.name.$dirty && !$v.user.name.required ? 'form_reqiered' : 'empty') | localize"
              ref="name"
              outlined
            ></v-text-field>

            <div class="profile-container_empty">{{ 'empty' | localize }}</div>

            <v-text-field
              class="input profile-container_personal-data__field v-input-phone"
              v-model="user.phone"
              v-mask.hide-on-empty="phoneMask"
              :class="{invalid: $v.user.phone.$dirty && !$v.user.phone.required}"
              :label="'numberPhone' | localize"
              placeholder="050 123 4567"
              :hint="($v.user.phone.$dirty && !$v.user.phone.required ? 'form_reqiered_number' : 'empty') | localize"
              type="tel"
              @input="checkForm($v.user.phone)"
              @blur="$v.user.phone.$touch()"
              ref="phone"
              outlined
            ></v-text-field>

            <v-text-field
              class="input profile-container_personal-data__field"
              :label="'email_address' | localize"
              :placeholder="'email_address' | localize"
              v-model="user.email"
              type="email"
              @change="checkForm($v.user.email)"
              @blur="$v.user.email.$touch()"
              :class="{invalid: ($v.user.email.$dirty && !$v.user.email.required) || ($v.user.email.$dirty && !$v.user.email.email) || ($v.user.email.$dirty && !$v.user.email.alpha)}"
              :hint="hintArray(
                $v.user.email, [
                  {rule: $v.user.email.required, message: 'form_reqiered_email'},
                  {rule: $v.user.email.alpha, message: 'form_wrong_abc_email'},
                  {rule: $v.user.email.email, message: 'form_wrong_email'}
                ]
              ) | localize"
              ref="email"
              outlined
            ></v-text-field>
          </div>

          <div class="profile-container_passport-data">
            <div class="profile-container_address">
              <div class="profile-container_title profile-container_title2">
                {{ "residenceAddress" | localize }}
              </div>
              <div class="profile-container_data">
                <v-autocomplete
                  v-model="user.city_id"
                  :items="cities"
                  item-text="name"
                  item-value="value"
                  cache-items
                  class="input profile-container_personal-data__field v-text-field-profile"
                  :class="{invalid: $v.user.city_id.$dirty && !$v.user.city_id.required}"
                  @input="checkForm($v.user.city_id)"
                  @blur="$v.user.city_id.$touch()"
                  :placeholder="'address_city' | localize"
                  append-icon="icon-arrow-down"
                  ref="city"
                  dark
                ></v-autocomplete>
                <v-text-field
                  v-model="user.street"
                  class="input profile-container_personal-data__field v-text-field-profile"
                  :class="{invalid: $v.user.street.$dirty && !$v.user.street.required}"
                  @input="checkForm($v.user.street)"
                  @blur="$v.user.street.$touch()"
                  :placeholder="'address_street' | localize"
                  dark
                ></v-text-field>
                <v-text-field
                  v-model="user.house"
                  class="input profile-container_personal-data__field v-text-field-profile"
                  :class="{invalid: $v.user.house.$dirty && !$v.user.house.required}"
                  @change="checkForm($v.user.house)"
                  @blur="$v.user.house.$touch()"
                  :placeholder="'address_house-float' | localize"
                  dark
                ></v-text-field>
              </div>
            </div>

            <div class="profile-container_passport">
              <div class="profile-container_title profile-container_title3">
                {{ "passportData" | localize }}
              </div>
              <div class="profile-container_data">
                <v-text-field
                  class="input profile-container_personal-data__field v-text-field-profile"
                  v-mask.hide-on-empty="passportMask"
                  :class="{invalid: ($v.user.passport_number.$dirty && !$v.user.passport_number.required) || ($v.user.passport_number.$dirty && !$v.user.passport_number.minLength) || ($v.user.passport_number.$dirty && !$v.user.passport_number.maxLength)}"
                  @input="checkForm($v.user.passport_number)"
                  @blur="$v.user.passport_number.$touch()"
                  :placeholder="'passport_seriesNumber' | localize"
                  dark
                  v-model="user.passport_number"
                ></v-text-field>
                <!-- Only for ru lang >>> <v-text-field
                  class="input profile-container_personal-data__field"
                  :placeholder="'passport_who-issued' | localize"
                  dark
                  v-model="user.passport_issued"
                ></v-text-field> -->

                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="user.passport_date_formatted"
                      class="input input_number profile-container_personal-data__field profile-container_personal-data__field_date v-text-field-profile"
                      :class="{ invalid: ($v.user.passport_date.$dirty && !$v.user.passport_date.required) || ($v.user.passport_date.$dirty && !$v.user.passport_date.validDate) }"
                      :hint="hintArray($v.user.passport_date, [{rule: $v.user.passport_date.required, message: 'form_reqiered'}, {rule: $v.user.passport_date.validDate, message: 'form_wrong_date'}]) | localize"
                      :placeholder="'passport_date-of-issue' | localize"
                      @change="checkForm($v.user.passport_date_formatted)"
                      @focus="user.passport_date = parseDate(user.passport_date_formatted)"
                      @blur="user.passport_date = parseDate(user.passport_date_formatted); $v.user.passport_date.$touch()"
                      v-mask.hide-on-empty="dateMask"
                      persistent-hint
                      append-icon="icon-calendar"
                      ref="date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="user.passport_date"
                    no-title
                    full-width
                    :max="dateTo"
                    @input="menu = false; user.passport_date_formatted = formatDate(user.passport_date)"
                  ></v-date-picker>
                </v-menu>

                <v-text-field
                  v-model="user.passport_place"
                  class="input profile-container_personal-data__field v-text-field-profile"
                  :class="{invalid: $v.user.passport_place.$dirty && !$v.user.passport_place.required}"
                  :placeholder="'passport_city-of-birth' | localize"
                  @input="checkForm($v.user.passport_place)"
                  @blur="$v.user.passport_place.$touch()"
                  dark
                ></v-text-field>
              </div>
            </div>
          </div>

          <div v-if="services.length > 1" class="profile-container_personal-data profile-container_personal-data_service">
            <div class="profile-container_title profile-container_title4">{{ "select_service" | localize }}</div>
            <v-select
              :items="services"
              class="service__select"
              :label="'documents_service' | localize"
              item-text="name"
              item-value="service_id"
              outlined
              v-model="currentService"
            ></v-select>
          </div>

          <div class="error__container">
            <br><p class="error" v-if="error">{{ error | localize }}</p>
          </div>

          <button type="button" class="button button__form" @click="submitHandler" :disabled="!success" :class="{ button__form_disabled: !success }">
            {{ "continue" | localize }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import VueCookies from 'vue-cookies'
import { email, required, minLength, maxLength } from 'vuelidate/lib/validators'

const user_data = VueCookies.get('user') || {}

export default {
  name: 'personal-profile',
  components: { Backlink },
  mixins: [formMixin, commonMixin],
  props: { service: { type: String, default: null } },
  data () {
    return {
      currentServiceStr: null,
      error: null,
      menu: false,
      phoneMask: [/0/, /5|7/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
      passportMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
      cities: [],
      dateTo: new Date().toISOString().substr(0, 10),
      userId: user_data.id || 0,
      user: null
    }
  },
  validations: {
    user: {
      name: { required },
      phone: { required },
      email: { required, email, alpha: val => /^[a-z]/i.test(val) },
      passport_date: { required, validDate: v => formMixin.methods.isValidDate(v) },
      passport_number: { required, minLength: minLength(9), maxLength: maxLength(9) },
      passport_place: { required },
      city_id: { required },
      street: { required },
      house: { required }
    }
  },
  watch: { 
    currentService () {
      this.changedService()
    }
  },
  created () {
    this.loadServices()

    this.user = {
      name: user_data.name || null,
      phone: user_data.phone || null,
      email: user_data.email || null,
      passport_date: user_data.passport_date || null,
      passport_date_formatted: user_data.passport_date ? this.formatDate(user_data.passport_date) : null,
    }
  },
  mounted () {
    this.$store.commit('loading', true)
    this.axios
      .get('users/' + this.userId)
      .then((response) => {
        this.user = response.data || {}
        this.user.passport_date_formatted = this.formatDate(response.data.passport_date)
        this.checkForm()
        this.$store.commit('loading', false)
      })
      .catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })

    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
      })
      .catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  },
  methods: {
    changedService() {
      this.currentServiceStr = this.getServiceStr(this.currentService)
    },
    loadAddress (serviceId) {
      if (!this.service) this.currentServiceStr = this.getServiceStr(serviceId)
      const service = this.$cookies.get(this.currentServiceStr) || {}
      if (service.clients && service.clients[1]) {
        this.user.city_id = service.clients[1].city || null
        this.user.street = service.clients[1].street || null
        this.user.house = service.clients[1].house || null
      }
    },
    gotoServiceContinueURL (serviceId) {
      switch (serviceId) {
        case 1: this.$router.push('/mortgage/realty'); break
        case 2: this.$router.push('/refinance-mortgage/banks'); break
        case 3: this.$router.push('/credit/income'); break
        case 4: this.$router.push('/refinance-credit/income'); break
        default: this.$router.push('/'); break
      }
    },
    goToHome () {
      this.$router.push('/')
    },
    submitHandler () {
      if (this.$v.user.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      this.success = true
      this.$store.commit('loading', true)
      this.error = null

      this.axios
        .put('users/' + this.userId, this.user)
        .then((response) => {
          const userData = response.data || {}
          this.user = {
            id: userData.id,
            name: userData.name,
            phone: userData.phone,
            photo: userData.photo,
            email: userData.email,
            token: this.$store.state.user.token || null
          }
          this.$store.commit('saveUser', this.user)
          this.$store.commit('loading', false)
          this.gotoServiceContinueURL(this.currentService)
        })
        .catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.b-personal-profile {
  .profile-container {
    &_title {
      font-weight: 500;
    }
    &_address {
      display: flex;
      flex-direction: column;
      width: 100%;
      .profile-container_data {
        height: 268px;
      }
    }
    &_data {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2), inset 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 100%;
      border: solid 2px #27a8bd;
      margin-top: 30px;
      padding: 50px 20px;
      position: relative;
      box-sizing: border-box;
    }

    &_personal-data {
      &_service {
        margin-top: 50px;
        height: 120px;
      }
    }
  }
}
.profile-container_empty {
  display: none;
}

#app.light-theme {
  .b-personal-profile {
    .profile-container {
      &_data {
        box-shadow: none;
        border: solid 2px $black;
      }
    }
  }
}

// Media styles
@media screen and (min-width: 1300px) {
  .profile-container_personal-data {
    grid-template-rows: 24px 1fr 1fr !important;
    grid-template-columns: 1fr 1fr !important;
    justify-items: stretch !important;
    grid-row-gap: 39px !important;
    grid-column-gap: 30px !important;
  }
  .profile-container_title {
    grid-column: 1/3 !important;
  }
  .profile-container_passport-data {
    margin-top: 50px;
    display: flex;
    .profile-container_title {
      margin-top: 0 !important;
    }
  }
  .profile-container_empty {
    display: block;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .profile-container_data {
    margin-bottom: 40px;
  }
  .b-personal-profile {
    .profile-container {
      &_title {
        margin: 0 0 20px;
      }
      &_title2 {
        margin: 30px 0 -10px;
      }
      &_title3 {
        margin: -10px 0 -10px !important;
      }

      &_personal-data {
        &_service {
          margin-top: 0;
          height: 110px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  #app {
    .b-personal-profile {
      .profile-container {
        &_title {
          margin: 0 0 20px;
        }
        &_title2 {
          margin: -20px 0 -10px;
        }
        &_title3 {
          margin: 30px 0 -10px;
        }

        &_personal-data__field {
          margin-bottom: 50px;
        }

        &_personal-data_service {
          margin-top: 30px;
        }

        .button {
          margin-bottom: 50px;
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  .b-personal-profile {
    .profile-container {
      &_title {
        grid-column: 1/3;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
      &_passport {
        display: flex;
        flex-direction: column;
        width: 100%;

        .profile-container_title {
          margin-top: 30px;
        }

        .profile-container_data {
          height: 268px;
          justify-content: space-between;
        }
      }
      &_address {
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .profile-container_data {
          height: 268px;
        }
      }
      &_data {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2), inset 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 100%;
        border: solid 2px #27a8bd;
        margin-top: 30px;
        padding: 50px 20px;
        position: relative;
        box-sizing: border-box;
      }
      &_personal-data {
        display: grid;
        grid-template-rows: 24px 1fr 1fr 1fr;
        grid-template-columns: 1fr;
        justify-items: stretch;
        background: none !important;
        grid-gap: 30px;

        .profile-container_title {
          grid-column: 1/2;
        }

        &__field {
          width: 100% !important;
          background: none !important;
          box-shadow: none;

          .v-messages__message {
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.4);
          }
          &::placeholder {
            color: rgba(255, 255, 255, 0.5) !important;
          }
          input::placeholder {
            color: rgba(255, 255, 255) !important;
          }
          .v-text-field__slot {
            align-items: flex-end;
          }
        }
        .v-input {
          margin: 0 !important;
        }
      }
    }
  }

  #app.locale_heb {
    .b-personal-profile .profile-container {
      direction: rtl;

      &_address {
        margin-right: 0;
        margin-left: 30px;
      }
    }
  }
}
</style>
